import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { getGroupedSlots, getWrittenDate } from '../../services/slotsFormatting';
import { colors } from '../home/v3/styledComponents';

const renderDate = (date) => {
  const end = moment(date).add(2, 'hour');
  return `${moment(date).format('dddd D MMMM YYYY [de] H[h]')} à ${end.format('H[h]')}`;
};

const renderPickup = (order) => {
  if (order.rdv3?.begin) return `Pickup rendu le ${renderDate(order.rdv3.begin)} avec notre Tilliste ${order.couturier.firstname}`;

  if (order.rdv2?.begin) {
    const isAvailableForNewOrderOnRdv2 = !order.couturier?.shopsNewOrderUnavailabilities
      ?.some((shopsNewOrderUnavailability) => moment(shopsNewOrderUnavailability).isSame(moment(order.rdv2.begin)));

    return `Pickup rendu le 
      ${renderDate(order.rdv2.begin)} avec notre Tilliste ${order.couturier.firstname}, ${isAvailableForNewOrderOnRdv2 ? 'disponible' : 'indisponible'} pour d'autres vêtements`;
  }

  if (order.rdv1?.begin && order.couturier?.firstname) return `Pickup prévu le ${renderDate(order.rdv1.begin)} avec notre Tilliste ${order.couturier.firstname}`;
  const groupedSlots = getGroupedSlots(order.rdv1Slots);
  const rdvLine = groupedSlots.reduce((acc, slot, index, arr) => {
    const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, index, arr);
    if (!acc) return `${rdvDate} entre ${rdvBegin} et ${rdvEnd}`;
    return `${acc}, ${rdvDate} entre ${rdvBegin} et ${rdvEnd}`;
  }, '').toLowerCase();
  return `Pickup en cours d‘assignation pour le ${rdvLine}`;
};

const sort = (order1, order2) => {
  const rdvOrder1 = order1.rdv3?.begin || order1.rdv2?.begin || order1.rdv1?.begin;
  const rdvOrder2 = order2.rdv3?.begin || order2.rdv2?.begin || order2.rdv1?.begin;
  return moment(rdvOrder1).diff(moment(rdvOrder2));
};

const PickUpLine = styled.p`
  color: ${({ order }) => {
    if (order.rdv2?.begin) return colors.navy;
    return colors.gold;
  }};
`;

const PickupList = ({ orders }) => (
  <>
    {orders?.sort(sort).map((order) => (
      <PickUpLine key={order?._id} order={order}>
        {renderPickup(order)}
      </PickUpLine>
    ))}
  </>
);

PickupList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    couturier: PropTypes.string,
  })).isRequired,
};

export default PickupList;
